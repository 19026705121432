enifed('ember-glimmer/environment', ['exports', 'ember-babel', '@glimmer/runtime', 'ember-debug', 'ember-metal', 'ember-utils', 'ember-views', 'ember-glimmer/component-managers/curly', 'ember-glimmer/syntax', 'ember-glimmer/utils/debug-stack', 'ember-glimmer/utils/iterable', 'ember-glimmer/utils/references', 'ember-glimmer/helpers/-class', 'ember-glimmer/helpers/-html-safe', 'ember-glimmer/helpers/-input-type', 'ember-glimmer/helpers/-normalize-class', 'ember-glimmer/helpers/action', 'ember-glimmer/helpers/component', 'ember-glimmer/helpers/concat', 'ember-glimmer/helpers/each-in', 'ember-glimmer/helpers/get', 'ember-glimmer/helpers/hash', 'ember-glimmer/helpers/if-unless', 'ember-glimmer/helpers/log', 'ember-glimmer/helpers/mut', 'ember-glimmer/helpers/query-param', 'ember-glimmer/helpers/readonly', 'ember-glimmer/helpers/unbound', 'ember-glimmer/modifiers/action', 'ember-glimmer/protocol-for-url', 'ember/features'], function (exports, _emberBabel, _runtime, _emberDebug, _emberMetal, _emberUtils, _emberViews, _curly, _syntax, _debugStack, _iterable, _references, _class, _htmlSafe, _inputType, _normalizeClass, _action, _component, _concat, _eachIn, _get, _hash, _ifUnless, _log, _mut, _queryParam, _readonly, _unbound, _action2, _protocolForUrl, _features) {
    'use strict';

    function instrumentationPayload(name) {
        return { object: 'component:' + name };
    }
    function isTemplateFactory(template) {
        return typeof template.create === 'function';
    }

    var Environment = function (_GlimmerEnvironment) {
        (0, _emberBabel.inherits)(Environment, _GlimmerEnvironment);

        Environment.create = function create(options) {
            return new this(options);
        };

        function Environment(injections) {
            (0, _emberBabel.classCallCheck)(this, Environment);

            var _this = (0, _emberBabel.possibleConstructorReturn)(this, _GlimmerEnvironment.call(this, injections));

            _this.owner = injections[_emberUtils.OWNER];
            _this.isInteractive = _this.owner.lookup('-environment:main').isInteractive;
            // can be removed once https://github.com/tildeio/glimmer/pull/305 lands
            _this.destroyedComponents = [];
            (0, _protocolForUrl.default)(_this);
            _this._definitionCache = new _emberMetal.Cache(2000, function (_ref) {
                var name = _ref.name,
                    source = _ref.source,
                    owner = _ref.owner;

                var _lookupComponent = (0, _emberViews.lookupComponent)(owner, name, { source: source }),
                    componentFactory = _lookupComponent.component,
                    layout = _lookupComponent.layout;

                var customManager = void 0;
                if (componentFactory || layout) {
                    if (_features.GLIMMER_CUSTOM_COMPONENT_MANAGER) {
                        var managerId = layout && layout.meta.managerId;
                        if (managerId) {
                            customManager = owner.factoryFor('component-manager:' + managerId).class;
                        }
                    }
                    return new _curly.CurlyComponentDefinition(name, componentFactory, layout, undefined, customManager);
                }
                return undefined;
            }, function (_ref2) {
                var name = _ref2.name,
                    source = _ref2.source,
                    owner = _ref2.owner;

                var expandedName = source && _this._resolveLocalLookupName(name, source, owner) || name;
                var ownerGuid = (0, _emberUtils.guidFor)(owner);
                return ownerGuid + '|' + expandedName;
            });
            _this._templateCache = new _emberMetal.Cache(1000, function (_ref3) {
                var Template = _ref3.Template,
                    owner = _ref3.owner;

                if (isTemplateFactory(Template)) {
                    var _Template$create;

                    // we received a factory
                    return Template.create((_Template$create = { env: _this }, _Template$create[_emberUtils.OWNER] = owner, _Template$create));
                } else {
                    // we were provided an instance already
                    return Template;
                }
            }, function (_ref4) {
                var Template = _ref4.Template,
                    owner = _ref4.owner;
                return (0, _emberUtils.guidFor)(owner) + '|' + Template.id;
            });
            _this._compilerCache = new _emberMetal.Cache(10, function (Compiler) {
                return new _emberMetal.Cache(2000, function (template) {
                    var compilable = new Compiler(template);
                    return (0, _runtime.compileLayout)(compilable, _this);
                }, function (template) {
                    var owner = template.meta.owner;
                    return (0, _emberUtils.guidFor)(owner) + '|' + template.id;
                });
            }, function (Compiler) {
                return Compiler.id;
            });
            _this.builtInModifiers = {
                action: new _action2.default()
            };
            _this.builtInHelpers = {
                'if': _ifUnless.inlineIf,
                action: _action.default,
                concat: _concat.default,
                get: _get.default,
                hash: _hash.default,
                log: _log.default,
                mut: _mut.default,
                'query-params': _queryParam.default,
                readonly: _readonly.default,
                unbound: _unbound.default,
                'unless': _ifUnless.inlineUnless,
                '-class': _class.default,
                '-each-in': _eachIn.default,
                '-input-type': _inputType.default,
                '-normalize-class': _normalizeClass.default,
                '-html-safe': _htmlSafe.default,
                '-get-dynamic-var': _runtime.getDynamicVar
            };
            if (true) {
                _this.debugStack = new _debugStack.default();
            }
            return _this;
        }
        // this gets clobbered by installPlatformSpecificProtocolForURL
        // it really should just delegate to a platform specific injection


        Environment.prototype.protocolForURL = function protocolForURL(s) {
            return s;
        };

        Environment.prototype._resolveLocalLookupName = function _resolveLocalLookupName(name, source, owner) {
            return _features.EMBER_MODULE_UNIFICATION ? source + ':' + name : owner._resolveLocalLookupName(name, source);
        };

        Environment.prototype.macros = function macros() {
            var macros = _GlimmerEnvironment.prototype.macros.call(this);
            (0, _syntax.populateMacros)(macros.blocks, macros.inlines);
            return macros;
        };

        Environment.prototype.hasComponentDefinition = function hasComponentDefinition() {
            return false;
        };

        Environment.prototype.getComponentDefinition = function getComponentDefinition(name, _ref5) {
            var owner = _ref5.owner,
                moduleName = _ref5.moduleName;

            var finalizer = (0, _emberMetal._instrumentStart)('render.getComponentDefinition', instrumentationPayload, name);
            var source = moduleName && 'template:' + moduleName;
            var definition = this._definitionCache.get({ name: name, source: source, owner: owner });
            finalizer();
            // TODO the glimmer-vm wants this to always have a def
            // but internally we need it to sometimes be undefined
            return definition;
        };

        Environment.prototype.getTemplate = function getTemplate(Template, owner) {
            return this._templateCache.get({ Template: Template, owner: owner });
        };

        Environment.prototype.getCompiledBlock = function getCompiledBlock(Compiler, template) {
            var compilerCache = this._compilerCache.get(Compiler);
            return compilerCache.get(template);
        };

        Environment.prototype.hasPartial = function hasPartial(name, meta) {
            return (0, _emberViews.hasPartial)(name, meta.owner);
        };

        Environment.prototype.lookupPartial = function lookupPartial(name, meta) {
            var partial = {
                name: name,
                template: (0, _emberViews.lookupPartial)(name, meta.owner)
            };
            if (partial.template) {
                return partial;
            } else {
                throw new Error(name + ' is not a partial');
            }
        };

        Environment.prototype.hasHelper = function hasHelper(name, _ref6) {
            var owner = _ref6.owner,
                moduleName = _ref6.moduleName;

            if (name === 'component' || this.builtInHelpers[name]) {
                return true;
            }
            var options = { source: 'template:' + moduleName };
            return owner.hasRegistration('helper:' + name, options) || owner.hasRegistration('helper:' + name);
        };

        Environment.prototype.lookupHelper = function lookupHelper(name, meta) {
            if (name === 'component') {
                return function (vm, args) {
                    return (0, _component.default)(vm, args, meta);
                };
            }
            var owner = meta.owner,
                moduleName = meta.moduleName;

            var helper = this.builtInHelpers[name];
            if (helper) {
                return helper;
            }
            var options = moduleName && { source: 'template:' + moduleName } || {};
            var helperFactory = owner.factoryFor('helper:' + name, options) || owner.factoryFor('helper:' + name);
            // TODO: try to unify this into a consistent protocol to avoid wasteful closure allocations
            var HelperReference = void 0;
            if (helperFactory.class.isSimpleHelperFactory) {
                HelperReference = _references.SimpleHelperReference;
            } else if (helperFactory.class.isHelperFactory) {
                HelperReference = _references.ClassBasedHelperReference;
            } else {
                throw new Error(name + ' is not a helper');
            }
            return function (vm, args) {
                return HelperReference.create(helperFactory, vm, args.capture());
            };
        };

        Environment.prototype.hasModifier = function hasModifier(name) {
            return !!this.builtInModifiers[name];
        };

        Environment.prototype.lookupModifier = function lookupModifier(name) {
            var modifier = this.builtInModifiers[name];
            if (modifier) {
                return modifier;
            } else {
                throw new Error(name + ' is not a modifier');
            }
        };

        Environment.prototype.toConditionalReference = function toConditionalReference(reference) {
            return _references.ConditionalReference.create(reference);
        };

        Environment.prototype.iterableFor = function iterableFor(ref, key) {
            return (0, _iterable.default)(ref, key);
        };

        Environment.prototype.scheduleInstallModifier = function scheduleInstallModifier(modifier, manager) {
            if (this.isInteractive) {
                _GlimmerEnvironment.prototype.scheduleInstallModifier.call(this, modifier, manager);
            }
        };

        Environment.prototype.scheduleUpdateModifier = function scheduleUpdateModifier(modifier, manager) {
            if (this.isInteractive) {
                _GlimmerEnvironment.prototype.scheduleUpdateModifier.call(this, modifier, manager);
            }
        };

        Environment.prototype.didDestroy = function didDestroy(destroyable) {
            destroyable.destroy();
        };

        Environment.prototype.begin = function begin() {
            this.inTransaction = true;
            _GlimmerEnvironment.prototype.begin.call(this);
        };

        Environment.prototype.commit = function commit() {
            var destroyedComponents = this.destroyedComponents;
            this.destroyedComponents = [];
            // components queued for destruction must be destroyed before firing
            // `didCreate` to prevent errors when removing and adding a component
            // with the same name (would throw an error when added to view registry)
            for (var i = 0; i < destroyedComponents.length; i++) {
                destroyedComponents[i].destroy();
            }
            try {
                _GlimmerEnvironment.prototype.commit.call(this);
            } finally {
                this.inTransaction = false;
            }
        };

        return Environment;
    }(_runtime.Environment);

    exports.default = Environment;

    if (true) {
        var StyleAttributeManager = function (_AttributeManager) {
            (0, _emberBabel.inherits)(StyleAttributeManager, _AttributeManager);

            function StyleAttributeManager() {
                (0, _emberBabel.classCallCheck)(this, StyleAttributeManager);
                return (0, _emberBabel.possibleConstructorReturn)(this, _AttributeManager.apply(this, arguments));
            }

            StyleAttributeManager.prototype.setAttribute = function setAttribute(dom, element, value) {
                (true && (0, _emberDebug.warn)((0, _emberViews.constructStyleDeprecationMessage)(value), function () {
                    if (value === null || value === undefined || (0, _runtime.isSafeString)(value)) {
                        return true;
                    }
                    return false;
                }(), { id: 'ember-htmlbars.style-xss-warning' }));

                _AttributeManager.prototype.setAttribute.call(this, dom, element, value);
            };

            StyleAttributeManager.prototype.updateAttribute = function updateAttribute(dom, element, value) {
                (true && (0, _emberDebug.warn)((0, _emberViews.constructStyleDeprecationMessage)(value), function () {
                    if (value === null || value === undefined || (0, _runtime.isSafeString)(value)) {
                        return true;
                    }
                    return false;
                }(), { id: 'ember-htmlbars.style-xss-warning' }));

                _AttributeManager.prototype.updateAttribute.call(this, dom, element, value);
            };

            return StyleAttributeManager;
        }(_runtime.AttributeManager);

        var STYLE_ATTRIBUTE_MANANGER = new StyleAttributeManager('style');
        Environment.prototype.attributeFor = function (element, attribute, isTrusting) {
            if (attribute === 'style' && !isTrusting) {
                return STYLE_ATTRIBUTE_MANANGER;
            }
            return _runtime.Environment.prototype.attributeFor.call(this, element, attribute, isTrusting);
        };
    }
});