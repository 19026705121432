define("ember-cli-materialize/components/md-card-panel", ["exports", "ember-cli-materialize/templates/components/md-card-panel"], function (_exports, _mdCardPanel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _mdCardPanel.default,
    classNames: ['card-panel'],
    classNameBindings: ['class']
  });

  _exports.default = _default;
});