define('ember-keyboard/utils/listener-name', ['exports', 'ember-keyboard/fixtures/alt-key-names'], function (exports, _emberKeyboardFixturesAltKeyNames) {
  exports['default'] = listenerName;

  function convertArray(keyArray) {
    return keyArray.map(function (key) {
      return _emberKeyboardFixturesAltKeyNames['default'][key] || key;
    });
  }

  function sortedKeys(keyArray) {
    var convertedArray = convertArray(keyArray);

    return convertedArray.sort().join('+');
  }

  function listenerName(type) {
    var keyArray = arguments.length <= 1 || arguments[1] === undefined ? [] : arguments[1];

    var keys = keyArray.length === 0 ? '_all' : sortedKeys(keyArray);

    return type + ':' + keys;
  }
});