enifed('ember-glimmer/helpers/query-param', ['exports', 'ember-debug', 'ember-routing', 'ember-utils', 'ember-glimmer/utils/references'], function (exports, _emberDebug, _emberRouting, _emberUtils, _references) {
    'use strict';

    exports.default = function (_vm, args) {
        return new _references.InternalHelperReference(queryParams, args.capture());
    };

    /**
      This is a helper to be used in conjunction with the link-to helper.
      It will supply url query parameters to the target route.
    
      Example
    
      ```handlebars
      {{#link-to 'posts' (query-params direction="asc")}}Sort{{/link-to}}
      ```
    
      @method query-params
      @for Ember.Templates.helpers
      @param {Object} hash takes a hash of query parameters
      @return {Object} A `QueryParams` object for `{{link-to}}`
      @public
    */
    function queryParams(_ref) {
        var positional = _ref.positional,
            named = _ref.named;
        (true && !(positional.value().length === 0) && (0, _emberDebug.assert)('The `query-params` helper only accepts hash parameters, e.g. (query-params queryParamPropertyName=\'foo\') as opposed to just (query-params \'foo\')', positional.value().length === 0));

        return _emberRouting.QueryParams.create({
            values: (0, _emberUtils.assign)({}, named.value())
        });
    }
});