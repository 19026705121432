enifed('ember-runtime/mixins/target_action_support', ['exports', 'ember-environment', 'ember-metal', 'ember-debug'], function (exports, _emberEnvironment, _emberMetal, _emberDebug) {
  'use strict';

  exports.default = _emberMetal.Mixin.create({
    target: null,
    targetObject: (0, _emberMetal.descriptor)({
      configurable: true,
      enumerable: false,
      get: function () {
        var message = this + ' Usage of `targetObject` is deprecated. Please use `target` instead.';
        var options = { id: 'ember-runtime.using-targetObject', until: '3.5.0' };
        (true && !(false) && (0, _emberDebug.deprecate)(message, false, options));

        return this._targetObject;
      },
      set: function (value) {
        var message = this + ' Usage of `targetObject` is deprecated. Please use `target` instead.';
        var options = { id: 'ember-runtime.using-targetObject', until: '3.5.0' };
        (true && !(false) && (0, _emberDebug.deprecate)(message, false, options));

        this._targetObject = value;
      }
    }),
    action: null,
    actionContext: null,

    actionContextObject: (0, _emberMetal.computed)('actionContext', function () {
      var actionContext = (0, _emberMetal.get)(this, 'actionContext');

      if (typeof actionContext === 'string') {
        var value = (0, _emberMetal.get)(this, actionContext);
        if (value === undefined) {
          value = (0, _emberMetal.get)(_emberEnvironment.context.lookup, actionContext);
        }
        return value;
      } else {
        return actionContext;
      }
    }),

    /**
    Send an `action` with an `actionContext` to a `target`. The action, actionContext
    and target will be retrieved from properties of the object. For example:
     ```javascript
    App.SaveButtonView = Ember.View.extend(Ember.TargetActionSupport, {
      target: Ember.computed.alias('controller'),
      action: 'save',
      actionContext: Ember.computed.alias('context'),
      click() {
        this.triggerAction(); // Sends the `save` action, along with the current context
                              // to the current controller
      }
    });
    ```
     The `target`, `action`, and `actionContext` can be provided as properties of
    an optional object argument to `triggerAction` as well.
     ```javascript
    App.SaveButtonView = Ember.View.extend(Ember.TargetActionSupport, {
      click() {
        this.triggerAction({
          action: 'save',
          target: this.get('controller'),
          actionContext: this.get('context')
        }); // Sends the `save` action, along with the current context
            // to the current controller
      }
    });
    ```
     The `actionContext` defaults to the object you are mixing `TargetActionSupport` into.
    But `target` and `action` must be specified either as properties or with the argument
    to `triggerAction`, or a combination:
     ```javascript
    App.SaveButtonView = Ember.View.extend(Ember.TargetActionSupport, {
      target: Ember.computed.alias('controller'),
      click() {
        this.triggerAction({
          action: 'save'
        }); // Sends the `save` action, along with a reference to `this`,
            // to the current controller
      }
    });
    ```
     @method triggerAction
    @param opts {Object} (optional, with the optional keys action, target and/or actionContext)
    @return {Boolean} true if the action was sent successfully and did not return false
    @private
    */
    triggerAction: function () {
      var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var action = opts.action,
          target = opts.target,
          actionContext = opts.actionContext;

      action = action || (0, _emberMetal.get)(this, 'action');
      target = target || getTarget(this);

      if (actionContext === undefined) {
        actionContext = (0, _emberMetal.get)(this, 'actionContextObject') || this;
      }

      if (target && action) {
        var ret = void 0;

        if (target.send) {
          var _target;

          ret = (_target = target).send.apply(_target, [action].concat(actionContext));
        } else {
          var _target2;

          (true && !(typeof target[action] === 'function') && (0, _emberDebug.assert)('The action \'' + action + '\' did not exist on ' + target, typeof target[action] === 'function'));

          ret = (_target2 = target)[action].apply(_target2, [].concat(actionContext));
        }

        if (ret !== false) {
          return true;
        }
      }

      return false;
    }
  });


  function getTarget(instance) {
    var target = (0, _emberMetal.get)(instance, 'target');
    if (target) {
      if (typeof target === 'string') {
        var value = (0, _emberMetal.get)(instance, target);
        if (value === undefined) {
          value = (0, _emberMetal.get)(_emberEnvironment.context.lookup, target);
        }

        return value;
      } else {
        return target;
      }
    }

    // if a `targetObject` CP was provided, use it
    if (target) {
      return target;
    }

    // if _targetObject use it
    if (instance._targetObject) {
      return instance._targetObject;
    }

    return null;
  }
});