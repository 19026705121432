define("ember-cli-materialize/components/md-navbar", ["exports", "ember-cli-materialize/templates/components/md-navbar"], function (_exports, _mdNavbar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'nav',
    layout: _mdNavbar.default,
    homeRoute: 'index',
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments); // TODO: is this scheduling necessary?


      Ember.run.scheduleOnce('afterRender', this, this._setupNavbar);
    },
    _setupNavbar: function _setupNavbar() {
      if (Ember.typeOf(Ember.$('.button-collapse').sideNav) === 'function') {
        this.notifyPropertyChange('_sideNavId');
        this.$('.button-collapse').sideNav({
          closeOnClick: true
        });
      }
    },
    _sideNavId: Ember.computed(function () {
      return typeof FastBoot === 'undefined' ? "".concat(this.get('element.id'), "-sidenav") : '';
    }) // TODO: Unregister any listeners that $.sideNav() puts in place
    // _teardownNavbar() {
    //
    // }

  });

  _exports.default = _default;
});