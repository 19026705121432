define("ember-cli-materialize/components/md-tabs", ["exports", "ember-composability/mixins/parent-component-support", "ember-cli-materialize/templates/components/md-tabs"], function (_exports, _parentComponentSupport, _mdTabs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }

  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) { n[e] = r[e]; } return n; }

  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0) { ; } } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }

  function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }

  var _default = Ember.Component.extend(_parentComponentSupport.default, {
    layout: _mdTabs.default,
    classNames: ['materialize-tabs', 'row'],
    composableChildrenDebounceTime: 1,
    content: null,
    numTabs: Ember.computed.alias('composableChildren.length'),
    optionValuePath: 'id',
    optionLabelPath: 'title',
    colWidth: 2,
    selected: null,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this._updateIndicatorPosition(false);
    },
    _indicatorUpdater: Ember.observer('selected', 'content.[]', 'composableChildren.[]', function () {
      Ember.run.debounce(this, this._updateIndicatorPosition, 100);
    }),
    tabComponents: function tabComponents() {
      return Ember.A(this.get('composableChildren')) || Ember.A();
    },
    _updateIndicatorPosition: function _updateIndicatorPosition() {
      var _this = this;

      var animate = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

      if (!this.element) {
        return;
      }

      var _filter = (this.get('composableChildren') || []).filter(function (item) {
        return Ember.get(item, 'value') === _this.get('selected');
      }),
          _filter2 = _slicedToArray(_filter, 1),
          tabComponent = _filter2[0];

      var tabSetRect = this.element.getBoundingClientRect();

      if (tabComponent) {
        var tabRect = tabComponent.element.getBoundingClientRect();
        var cssParams = {
          left: tabRect.left - tabSetRect.left,
          right: tabSetRect.right - tabRect.right
        };

        if (!animate) {
          this.$('.indicator').css(cssParams);
        } else {
          this.$('.indicator1').velocity(cssParams, {
            duration: 150
          });
          this.$('.indicator2').velocity(cssParams, {
            duration: 150,
            delay: 40
          });
        }
      }
    },
    _content: Ember.computed('content.[]', 'optionLabelPath', 'optionValuePath', function () {
      var labelPath = this.get('optionLabelPath');
      var valuePath = this.get('optionValuePath');
      return new Ember.A((this.get('content') || []).map(function (contentItem) {
        return {
          id: contentItem[valuePath],
          title: contentItem[labelPath]
        };
      }));
    })
  });

  _exports.default = _default;
});