define("ember-ajax/request", ["exports", "ember-ajax/ajax-request"], function (_exports, _ajaxRequest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = request;

  /**
   * Helper function that allows you to use the default `ember-ajax` to make
   * requests without using the service.
   *
   * @public
   */
  function request(url, options) {
    var ajax = _ajaxRequest.default.create();

    return ajax.request(url, options);
  }
});