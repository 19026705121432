define("ember-cli-materialize/components/md-collapsible", ["exports", "ember-cli-materialize/templates/components/md-collapsible"], function (_exports, _mdCollapsible) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _mdCollapsible.default,
    tagName: 'li',
    // classNameBindings: ['class'],
    actionArg: null,
    model: Ember.computed('actionArg', {
      get: function get() {
        (true && !(false) && Ember.deprecate('md-collapsible#model is deprecated. Please use md-collapsible#actionArg instead'));
        return this.get('actionArg');
      },
      set: function set(key, val) {
        (true && !(false) && Ember.deprecate('md-collapsible#model is deprecated. Please use md-collapsible#actionArg instead'));
        return this.set('actionArg', val);
      }
    }),
    actions: {
      headerClicked: function headerClicked() {
        this.sendAction('action', this.get('actionArg'));
      }
    }
  });

  _exports.default = _default;
});