define("ember-cli-materialize/components/selectable-item", ["exports", "ember-composability/mixins/child-component-support", "ember-cli-materialize/components/selectable-item-group", "ember-new-computed"], function (_exports, _childComponentSupport, _selectableItemGroup, _emberNewComputed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }

  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) { n[e] = r[e]; } return n; }

  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0) { ; } } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }

  function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }

  var _default = Ember.Component.extend(_childComponentSupport.default, {
    // eslint-disable-next-line
    _parentComponentTypes: [_selectableItemGroup.default],
    checked: null,
    disabled: false,
    classNames: ['materialize-selectable-item'],
    _checked: (0, _emberNewComputed.default)('checked', 'group.selection', 'group.selection.[]', {
      get: function get() {
        var group = this.get('group');

        if (!group) {
          return this.get('checked');
        } else {
          return group.isValueSelected(this.get('value'));
        }
      },
      set: function set(key, val) {
        var group = this.get('group');

        if (!group) {
          this.set('checked', val);
        } else {
          group.setValueSelection(this.get('value'), val);
        }

        this.sendAction('action', {
          checked: !!val
        });
        return !!val;
      }
    }),
    isSelected: Ember.computed.alias('_checked'),
    _setupLabel: function _setupLabel() {
      var _this$$$toArray = this.$('.materialize-selectable-item-input, .materialize-selectable-item-input-container input').toArray(),
          _this$$$toArray2 = _slicedToArray(_this$$$toArray, 1),
          $input = _this$$$toArray2[0];

      var inputId = $input ? $input.id : null;
      this.$('.materialize-selectable-item-label').attr('for', inputId);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this._setupLabel();
    },
    group: Ember.computed(function () {
      return this.nearestWithProperty('__materializeSelectableItemGroup');
    })
  });

  _exports.default = _default;
});