enifed('ember-glimmer/views/outlet', ['exports', 'ember-babel', '@glimmer/reference', 'ember-environment', 'ember-metal', 'ember-utils'], function (exports, _emberBabel, _reference, _emberEnvironment, _emberMetal, _emberUtils) {
    'use strict';

    exports.RootOutletStateReference = undefined;

    var RootOutletStateReference = exports.RootOutletStateReference = function () {
        function RootOutletStateReference(outletView) {
            (0, _emberBabel.classCallCheck)(this, RootOutletStateReference);

            this.outletView = outletView;
            this.tag = outletView._tag;
        }

        RootOutletStateReference.prototype.get = function get(key) {
            return new ChildOutletStateReference(this, key);
        };

        RootOutletStateReference.prototype.value = function value() {
            return this.outletView.outletState;
        };

        RootOutletStateReference.prototype.getOrphan = function getOrphan(name) {
            return new OrphanedOutletStateReference(this, name);
        };

        RootOutletStateReference.prototype.update = function update(state) {
            this.outletView.setOutletState(state);
        };

        return RootOutletStateReference;
    }();

    var OrphanedOutletStateReference = function (_RootOutletStateRefer) {
        (0, _emberBabel.inherits)(OrphanedOutletStateReference, _RootOutletStateRefer);

        function OrphanedOutletStateReference(root, name) {
            (0, _emberBabel.classCallCheck)(this, OrphanedOutletStateReference);

            var _this = (0, _emberBabel.possibleConstructorReturn)(this, _RootOutletStateRefer.call(this, root.outletView));

            _this.root = root;
            _this.name = name;
            return _this;
        }

        OrphanedOutletStateReference.prototype.value = function value() {
            var rootState = this.root.value();
            var orphans = rootState.outlets.main.outlets.__ember_orphans__;
            if (!orphans) {
                return null;
            }
            var matched = orphans.outlets[this.name];
            if (!matched) {
                return null;
            }
            var state = Object.create(null);
            state[matched.render.outlet] = matched;
            matched.wasUsed = true;
            return { outlets: state, render: undefined };
        };

        return OrphanedOutletStateReference;
    }(RootOutletStateReference);

    var ChildOutletStateReference = function () {
        function ChildOutletStateReference(parent, key) {
            (0, _emberBabel.classCallCheck)(this, ChildOutletStateReference);

            this.parent = parent;
            this.key = key;
            this.tag = parent.tag;
        }

        ChildOutletStateReference.prototype.get = function get(key) {
            return new ChildOutletStateReference(this, key);
        };

        ChildOutletStateReference.prototype.value = function value() {
            var parent = this.parent.value();
            return parent && parent[this.key];
        };

        return ChildOutletStateReference;
    }();

    var OutletView = function () {
        OutletView.extend = function extend(injections) {
            return function (_OutletView) {
                (0, _emberBabel.inherits)(_class, _OutletView);

                function _class() {
                    (0, _emberBabel.classCallCheck)(this, _class);
                    return (0, _emberBabel.possibleConstructorReturn)(this, _OutletView.apply(this, arguments));
                }

                _class.create = function create(options) {
                    if (options) {
                        return _OutletView.create.call(this, (0, _emberUtils.assign)({}, injections, options));
                    } else {
                        return _OutletView.create.call(this, injections);
                    }
                };

                return _class;
            }(OutletView);
        };

        OutletView.reopenClass = function reopenClass(injections) {
            (0, _emberUtils.assign)(this, injections);
        };

        OutletView.create = function create(options) {
            var _environment = options._environment,
                renderer = options.renderer,
                template = options.template;

            var owner = options[_emberUtils.OWNER];
            return new OutletView(_environment, renderer, owner, template);
        };

        function OutletView(_environment, renderer, owner, template) {
            (0, _emberBabel.classCallCheck)(this, OutletView);

            this._environment = _environment;
            this.renderer = renderer;
            this.owner = owner;
            this.template = template;
            this.outletState = null;
            this._tag = _reference.DirtyableTag.create();
        }

        OutletView.prototype.appendTo = function appendTo(selector) {
            var env = this._environment || _emberEnvironment.environment;
            var target = void 0;
            if (env.hasDOM) {
                target = typeof selector === 'string' ? document.querySelector(selector) : selector;
            } else {
                target = selector;
            }
            _emberMetal.run.schedule('render', this.renderer, 'appendOutletView', this, target);
        };

        OutletView.prototype.rerender = function rerender() {};

        OutletView.prototype.setOutletState = function setOutletState(state) {
            this.outletState = {
                outlets: {
                    main: state
                },
                render: {
                    owner: undefined,
                    into: undefined,
                    outlet: 'main',
                    name: '-top-level',
                    controller: undefined,
                    template: undefined
                }
            };
            this._tag.inner.dirty();
        };

        OutletView.prototype.toReference = function toReference() {
            return new RootOutletStateReference(this);
        };

        OutletView.prototype.destroy = function destroy() {};

        return OutletView;
    }();

    exports.default = OutletView;
});