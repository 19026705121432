enifed('ember-glimmer/component-managers/abstract', ['exports', 'ember-babel'], function (exports, _emberBabel) {
    'use strict';

    var AbstractManager = function () {
        function AbstractManager() {
            (0, _emberBabel.classCallCheck)(this, AbstractManager);

            this.debugStack = undefined;
        }

        AbstractManager.prototype.prepareArgs = function prepareArgs(_definition, _args) {
            return null;
        };

        AbstractManager.prototype.didCreateElement = function didCreateElement(_component, _element, _operations) {}
        // noop

        // inheritors should also call `this.debugStack.pop()` to
        // ensure the rerendering assertion messages are properly
        // maintained
        ;

        AbstractManager.prototype.didRenderLayout = function didRenderLayout(_component, _bounds) {
            // noop
        };

        AbstractManager.prototype.didCreate = function didCreate(_bucket) {
            // noop
        };

        AbstractManager.prototype.getTag = function getTag(_bucket) {
            return null;
        };

        AbstractManager.prototype.update = function update(_bucket, _dynamicScope) {}
        // noop

        // inheritors should also call `this.debugStack.pop()` to
        // ensure the rerendering assertion messages are properly
        // maintained
        ;

        AbstractManager.prototype.didUpdateLayout = function didUpdateLayout(_bucket, _bounds) {
            // noop
        };

        AbstractManager.prototype.didUpdate = function didUpdate(_bucket) {
            // noop
        };

        return AbstractManager;
    }();

    exports.default = AbstractManager;

    if (true) {
        AbstractManager.prototype._pushToDebugStack = function (name, environment) {
            this.debugStack = environment.debugStack;
            this.debugStack.push(name);
        };
        AbstractManager.prototype._pushEngineToDebugStack = function (name, environment) {
            this.debugStack = environment.debugStack;
            this.debugStack.pushEngine(name);
        };
    }
});