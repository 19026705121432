define("ember-cli-materialize/components/md-collection", ["exports", "ember-cli-materialize/templates/components/md-collection"], function (_exports, _mdCollection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _mdCollection.default,
    classNames: ['collection'],
    classNameBindings: ['_hasHeader:with-header'],
    headerComponentName: 'md-default-collection-header',
    header: null,
    _hasHeader: Ember.computed.bool('header')
  });

  _exports.default = _default;
});