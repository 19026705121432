enifed('backburner', ['exports', 'ember-babel'], function (exports, _emberBabel) {
    'use strict';

    var NUMBER = /\d+/;
    function isString(suspect) {
        return typeof suspect === 'string';
    }
    function isFunction(suspect) {
        return typeof suspect === 'function';
    }
    function isNumber(suspect) {
        return typeof suspect === 'number';
    }
    function isCoercableNumber(suspect) {
        return isNumber(suspect) && suspect === suspect || NUMBER.test(suspect);
    }
    function noSuchQueue(name) {
        throw new Error('You attempted to schedule an action in a queue (' + name + ') that doesn\'t exist');
    }
    function noSuchMethod(name) {
        throw new Error('You attempted to schedule an action in a queue (' + name + ') for a method that doesn\'t exist');
    }
    function getOnError(options) {
        return options.onError || options.onErrorTarget && options.onErrorTarget[options.onErrorMethod];
    }
    function findItem(target, method, collection) {
        var index = -1;
        for (var i = 0, l = collection.length; i < l; i += 4) {
            if (collection[i] === target && collection[i + 1] === method) {
                index = i;
                break;
            }
        }
        return index;
    }
    function findTimer(timer, collection) {
        var index = -1;
        for (var i = 3; i < collection.length; i += 4) {
            if (collection[i] === timer) {
                index = i - 3;
                break;
            }
        }
        return index;
    }

    function binarySearch(time, timers) {
        var start = 0;
        var end = timers.length - 2;
        var middle = void 0;
        var l = void 0;
        while (start < end) {
            // since timers is an array of pairs 'l' will always
            // be an integer
            l = (end - start) / 2;
            // compensate for the index in case even number
            // of pairs inside timers
            middle = start + l - l % 2;
            if (time >= timers[middle]) {
                start = middle + 2;
            } else {
                end = middle;
            }
        }
        return time >= timers[start] ? start + 2 : start;
    }

    var Queue = function () {
        function Queue(name) {
            var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
            var globalOptions = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
            (0, _emberBabel.classCallCheck)(this, Queue);

            this._queueBeingFlushed = [];
            this.targetQueues = Object.create(null);
            this.index = 0;
            this._queue = [];
            this.name = name;
            this.options = options;
            this.globalOptions = globalOptions;
        }

        Queue.prototype.push = function push(target, method, args, stack) {
            this._queue.push(target, method, args, stack);
            return {
                queue: this,
                target: target,
                method: method
            };
        };

        Queue.prototype.pushUnique = function pushUnique(target, method, args, stack) {
            var guid = this.guidForTarget(target);
            if (guid) {
                this.pushUniqueWithGuid(guid, target, method, args, stack);
            } else {
                this.pushUniqueWithoutGuid(target, method, args, stack);
            }
            return {
                queue: this,
                target: target,
                method: method
            };
        };

        Queue.prototype.flush = function flush(sync) {
            var _options = this.options,
                before = _options.before,
                after = _options.after;

            var target = void 0;
            var method = void 0;
            var args = void 0;
            var errorRecordedForStack = void 0;
            this.targetQueues = Object.create(null);
            if (this._queueBeingFlushed.length === 0) {
                this._queueBeingFlushed = this._queue;
                this._queue = [];
            }
            if (before !== undefined) {
                before();
            }
            var invoke = void 0;
            var queueItems = this._queueBeingFlushed;
            if (queueItems.length > 0) {
                var onError = getOnError(this.globalOptions);
                invoke = onError ? this.invokeWithOnError : this.invoke;
                for (var i = this.index; i < queueItems.length; i += 4) {
                    this.index += 4;
                    method = queueItems[i + 1];
                    // method could have been nullified / canceled during flush
                    if (method !== null) {
                        //
                        //    ** Attention intrepid developer **
                        //
                        //    To find out the stack of this task when it was scheduled onto
                        //    the run loop, add the following to your app.js:
                        //
                        //    Ember.run.backburner.DEBUG = true; // NOTE: This slows your app, don't leave it on in production.
                        //
                        //    Once that is in place, when you are at a breakpoint and navigate
                        //    here in the stack explorer, you can look at `errorRecordedForStack.stack`,
                        //    which will be the captured stack when this job was scheduled.
                        //
                        //    One possible long-term solution is the following Chrome issue:
                        //       https://bugs.chromium.org/p/chromium/issues/detail?id=332624
                        //
                        target = queueItems[i];
                        args = queueItems[i + 2];
                        errorRecordedForStack = queueItems[i + 3]; // Debugging assistance
                        invoke(target, method, args, onError, errorRecordedForStack);
                    }
                    if (this.index !== this._queueBeingFlushed.length && this.globalOptions.mustYield && this.globalOptions.mustYield()) {
                        return 1 /* Pause */;
                    }
                }
            }
            if (after !== undefined) {
                after();
            }
            this._queueBeingFlushed.length = 0;
            this.index = 0;
            if (sync !== false && this._queue.length > 0) {
                // check if new items have been added
                this.flush(true);
            }
        };

        Queue.prototype.hasWork = function hasWork() {
            return this._queueBeingFlushed.length > 0 || this._queue.length > 0;
        };

        Queue.prototype.cancel = function cancel(_ref) {
            var target = _ref.target,
                method = _ref.method;

            var queue = this._queue;
            var guid = this.guidForTarget(target);
            var targetQueue = guid ? this.targetQueues[guid] : undefined;
            if (targetQueue !== undefined) {
                var t = void 0;
                for (var i = 0, l = targetQueue.length; i < l; i += 2) {
                    t = targetQueue[i];
                    if (t === method) {
                        targetQueue.splice(i, 2);
                        break;
                    }
                }
            }
            var index = findItem(target, method, queue);
            if (index > -1) {
                queue.splice(index, 4);
                return true;
            }
            // if not found in current queue
            // could be in the queue that is being flushed
            queue = this._queueBeingFlushed;
            index = findItem(target, method, queue);
            if (index > -1) {
                queue[index + 1] = null;
                return true;
            }
            return false;
        };

        Queue.prototype.guidForTarget = function guidForTarget(target) {
            if (!target) {
                return;
            }
            var peekGuid = this.globalOptions.peekGuid;
            if (peekGuid) {
                return peekGuid(target);
            }
            var KEY = this.globalOptions.GUID_KEY;
            if (KEY) {
                return target[KEY];
            }
        };

        Queue.prototype.pushUniqueWithoutGuid = function pushUniqueWithoutGuid(target, method, args, stack) {
            var queue = this._queue;
            var index = findItem(target, method, queue);
            if (index > -1) {
                queue[index + 2] = args; // replace args
                queue[index + 3] = stack; // replace stack
            } else {
                queue.push(target, method, args, stack);
            }
        };

        Queue.prototype.targetQueue = function targetQueue(_targetQueue, target, method, args, stack) {
            var queue = this._queue;
            for (var i = 0, l = _targetQueue.length; i < l; i += 2) {
                var currentMethod = _targetQueue[i];
                if (currentMethod === method) {
                    var currentIndex = _targetQueue[i + 1];
                    queue[currentIndex + 2] = args; // replace args
                    queue[currentIndex + 3] = stack; // replace stack
                    return;
                }
            }
            _targetQueue.push(method, queue.push(target, method, args, stack) - 4);
        };

        Queue.prototype.pushUniqueWithGuid = function pushUniqueWithGuid(guid, target, method, args, stack) {
            var localQueue = this.targetQueues[guid];
            if (localQueue !== undefined) {
                this.targetQueue(localQueue, target, method, args, stack);
            } else {
                this.targetQueues[guid] = [method, this._queue.push(target, method, args, stack) - 4];
            }
        };

        Queue.prototype.invoke = function invoke(target, method, args /*, onError, errorRecordedForStack */) {
            if (args !== undefined) {
                method.apply(target, args);
            } else {
                method.call(target);
            }
        };

        Queue.prototype.invokeWithOnError = function invokeWithOnError(target, method, args, onError, errorRecordedForStack) {
            try {
                if (args !== undefined) {
                    method.apply(target, args);
                } else {
                    method.call(target);
                }
            } catch (error) {
                onError(error, errorRecordedForStack);
            }
        };

        return Queue;
    }();

    var DeferredActionQueues = function () {
        function DeferredActionQueues() {
            var queueNames = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
            var options = arguments[1];
            (0, _emberBabel.classCallCheck)(this, DeferredActionQueues);

            this.queues = {};
            this.queueNameIndex = 0;
            this.queueNames = queueNames;
            queueNames.reduce(function (queues, queueName) {
                queues[queueName] = new Queue(queueName, options[queueName], options);
                return queues;
            }, this.queues);
        }
        /*
          @method schedule
          @param {String} queueName
          @param {Any} target
          @param {Any} method
          @param {Any} args
          @param {Boolean} onceFlag
          @param {Any} stack
          @return queue
        */


        DeferredActionQueues.prototype.schedule = function schedule(queueName, target, method, args, onceFlag, stack) {
            var queues = this.queues;
            var queue = queues[queueName];
            if (!queue) {
                noSuchQueue(queueName);
            }
            if (!method) {
                noSuchMethod(queueName);
            }
            if (onceFlag) {
                return queue.pushUnique(target, method, args, stack);
            } else {
                return queue.push(target, method, args, stack);
            }
        };

        DeferredActionQueues.prototype.flush = function flush() {
            var queue = void 0;
            var queueName = void 0;
            var numberOfQueues = this.queueNames.length;
            while (this.queueNameIndex < numberOfQueues) {
                queueName = this.queueNames[this.queueNameIndex];
                queue = this.queues[queueName];
                if (queue.hasWork() === false) {
                    this.queueNameIndex++;
                } else {
                    if (queue.flush(false /* async */) === 1 /* Pause */) {
                            return 1 /* Pause */;
                        }
                    this.queueNameIndex = 0; // only reset to first queue if non-pause break
                }
            }
        };

        return DeferredActionQueues;
    }();

    // accepts a function that when invoked will return an iterator
    // iterator will drain until completion
    var iteratorDrain = function (fn) {
        var iterator = fn();
        var result = iterator.next();
        while (result.done === false) {
            result.value();
            result = iterator.next();
        }
    };

    var noop = function () {};
    var SET_TIMEOUT = setTimeout;
    function parseArgs() {
        var length = arguments.length;
        var method = void 0;
        var target = void 0;
        var args = void 0;
        if (length === 1) {
            method = arguments[0];
            target = null;
        } else {
            target = arguments[0];
            method = arguments[1];
            if (isString(method)) {
                method = target[method];
            }
            if (length > 2) {
                args = new Array(length - 2);
                for (var i = 0, l = length - 2; i < l; i++) {
                    args[i] = arguments[i + 2];
                }
            }
        }
        return [target, method, args];
    }

    var Backburner = function () {
        function Backburner(queueNames) {
            var _this = this;

            var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
            (0, _emberBabel.classCallCheck)(this, Backburner);

            this.DEBUG = false;
            this.currentInstance = null;
            this._timerTimeoutId = null;
            this._autorun = null;
            this.queueNames = queueNames;
            this.options = options;
            if (!this.options.defaultQueue) {
                this.options.defaultQueue = queueNames[0];
            }
            this.instanceStack = [];
            this._timers = [];
            this._debouncees = [];
            this._throttlers = [];
            this._eventCallbacks = {
                end: [],
                begin: []
            };
            this._onBegin = this.options.onBegin || noop;
            this._onEnd = this.options.onEnd || noop;
            var _platform = this.options._platform || {};
            var platform = Object.create(null);
            platform.setTimeout = _platform.setTimeout || function (fn, ms) {
                return setTimeout(fn, ms);
            };
            platform.clearTimeout = _platform.clearTimeout || function (id) {
                return clearTimeout(id);
            };
            platform.next = _platform.next || function (fn) {
                return SET_TIMEOUT(fn, 0);
            };
            platform.clearNext = _platform.clearNext || platform.clearTimeout;
            platform.now = _platform.now || function () {
                return Date.now();
            };
            this._platform = platform;
            this._boundRunExpiredTimers = function () {
                _this._runExpiredTimers();
            };
            this._boundAutorunEnd = function () {
                _this._autorun = null;
                _this.end();
            };
        }
        /*
          @method begin
          @return instantiated class DeferredActionQueues
        */


        Backburner.prototype.begin = function begin() {
            var options = this.options;
            var previousInstance = this.currentInstance;
            var current = void 0;
            if (this._autorun !== null) {
                current = previousInstance;
                this._cancelAutorun();
            } else {
                if (previousInstance !== null) {
                    this.instanceStack.push(previousInstance);
                }
                current = this.currentInstance = new DeferredActionQueues(this.queueNames, options);
                this._trigger('begin', current, previousInstance);
            }
            this._onBegin(current, previousInstance);
            return current;
        };

        Backburner.prototype.end = function end() {
            var currentInstance = this.currentInstance;
            var nextInstance = null;
            if (currentInstance === null) {
                throw new Error('end called without begin');
            }
            // Prevent double-finally bug in Safari 6.0.2 and iOS 6
            // This bug appears to be resolved in Safari 6.0.5 and iOS 7
            var finallyAlreadyCalled = false;
            var result = void 0;
            try {
                result = currentInstance.flush();
            } finally {
                if (!finallyAlreadyCalled) {
                    finallyAlreadyCalled = true;
                    if (result === 1 /* Pause */) {
                            var next = this._platform.next;
                            this._autorun = next(this._boundAutorunEnd);
                        } else {
                        this.currentInstance = null;
                        if (this.instanceStack.length > 0) {
                            nextInstance = this.instanceStack.pop();
                            this.currentInstance = nextInstance;
                        }
                        this._trigger('end', currentInstance, nextInstance);
                        this._onEnd(currentInstance, nextInstance);
                    }
                }
            }
        };

        Backburner.prototype.on = function on(eventName, callback) {
            if (typeof callback !== 'function') {
                throw new TypeError('Callback must be a function');
            }
            var callbacks = this._eventCallbacks[eventName];
            if (callbacks !== undefined) {
                callbacks.push(callback);
            } else {
                throw new TypeError('Cannot on() event ' + eventName + ' because it does not exist');
            }
        };

        Backburner.prototype.off = function off(eventName, callback) {
            var callbacks = this._eventCallbacks[eventName];
            if (!eventName || callbacks === undefined) {
                throw new TypeError('Cannot off() event ' + eventName + ' because it does not exist');
            }
            var callbackFound = false;
            if (callback) {
                for (var i = 0; i < callbacks.length; i++) {
                    if (callbacks[i] === callback) {
                        callbackFound = true;
                        callbacks.splice(i, 1);
                        i--;
                    }
                }
            }
            if (!callbackFound) {
                throw new TypeError('Cannot off() callback that does not exist');
            }
        };

        Backburner.prototype.run = function run() {
            var _parseArgs = parseArgs.apply(undefined, arguments),
                target = _parseArgs[0],
                method = _parseArgs[1],
                args = _parseArgs[2];

            return this._run(target, method, args);
        };

        Backburner.prototype.join = function join() {
            var _parseArgs2 = parseArgs.apply(undefined, arguments),
                target = _parseArgs2[0],
                method = _parseArgs2[1],
                args = _parseArgs2[2];

            return this._join(target, method, args);
        };

        Backburner.prototype.defer = function defer() {
            return this.schedule.apply(this, arguments);
        };

        Backburner.prototype.schedule = function schedule(queueName) {
            for (var _len = arguments.length, _args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
                _args[_key - 1] = arguments[_key];
            }

            var _parseArgs3 = parseArgs.apply(undefined, _args),
                target = _parseArgs3[0],
                method = _parseArgs3[1],
                args = _parseArgs3[2];

            var stack = this.DEBUG ? new Error() : undefined;
            return this._ensureInstance().schedule(queueName, target, method, args, false, stack);
        };

        Backburner.prototype.scheduleIterable = function scheduleIterable(queueName, iterable) {
            var stack = this.DEBUG ? new Error() : undefined;
            return this._ensureInstance().schedule(queueName, null, iteratorDrain, [iterable], false, stack);
        };

        Backburner.prototype.deferOnce = function deferOnce() {
            return this.scheduleOnce.apply(this, arguments);
        };

        Backburner.prototype.scheduleOnce = function scheduleOnce(queueName) {
            for (var _len2 = arguments.length, _args = Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
                _args[_key2 - 1] = arguments[_key2];
            }

            var _parseArgs4 = parseArgs.apply(undefined, _args),
                target = _parseArgs4[0],
                method = _parseArgs4[1],
                args = _parseArgs4[2];

            var stack = this.DEBUG ? new Error() : undefined;
            return this._ensureInstance().schedule(queueName, target, method, args, true, stack);
        };

        Backburner.prototype.setTimeout = function setTimeout() {
            return this.later.apply(this, arguments);
        };

        Backburner.prototype.later = function later() {
            for (var _len3 = arguments.length, args = Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
                args[_key3] = arguments[_key3];
            }

            var length = args.length;
            var wait = 0;
            var method = void 0;
            var target = void 0;
            var methodOrTarget = void 0;
            var methodOrWait = void 0;
            var methodOrArgs = void 0;
            if (length === 0) {
                return;
            } else if (length === 1) {
                method = args.shift();
            } else if (length === 2) {
                methodOrTarget = args[0];
                methodOrWait = args[1];
                if (isFunction(methodOrWait)) {
                    target = args.shift();
                    method = args.shift();
                } else if (methodOrTarget !== null && isString(methodOrWait) && methodOrWait in methodOrTarget) {
                    target = args.shift();
                    method = target[args.shift()];
                } else if (isCoercableNumber(methodOrWait)) {
                    method = args.shift();
                    wait = parseInt(args.shift(), 10);
                } else {
                    method = args.shift();
                }
            } else {
                var last = args[args.length - 1];
                if (isCoercableNumber(last)) {
                    wait = parseInt(args.pop(), 10);
                }
                methodOrTarget = args[0];
                methodOrArgs = args[1];
                if (isFunction(methodOrArgs)) {
                    target = args.shift();
                    method = args.shift();
                } else if (methodOrTarget !== null && isString(methodOrArgs) && methodOrArgs in methodOrTarget) {
                    target = args.shift();
                    method = target[args.shift()];
                } else {
                    method = args.shift();
                }
            }
            var onError = getOnError(this.options);
            var executeAt = this._platform.now() + wait;
            var fn = void 0;
            if (onError) {
                fn = function () {
                    try {
                        method.apply(target, args);
                    } catch (e) {
                        onError(e);
                    }
                };
            } else {
                fn = function () {
                    method.apply(target, args);
                };
            }
            return this._setTimeout(fn, executeAt);
        };

        Backburner.prototype.throttle = function throttle(targetOrThisArgOrMethod) {
            var _this2 = this;

            var target = void 0;
            var method = void 0;
            var immediate = void 0;
            var isImmediate = void 0;
            var wait = void 0;

            for (var _len4 = arguments.length, args = Array(_len4 > 1 ? _len4 - 1 : 0), _key4 = 1; _key4 < _len4; _key4++) {
                args[_key4 - 1] = arguments[_key4];
            }

            if (args.length === 1) {
                method = targetOrThisArgOrMethod;
                wait = args.pop();
                target = null;
                isImmediate = true;
            } else {
                target = targetOrThisArgOrMethod;
                method = args.shift();
                immediate = args.pop();
                if (isString(method)) {
                    method = target[method];
                } else if (!isFunction(method)) {
                    args.unshift(method);
                    method = target;
                    target = null;
                }
                if (isCoercableNumber(immediate)) {
                    wait = immediate;
                    isImmediate = true;
                } else {
                    wait = args.pop();
                    isImmediate = immediate === true;
                }
            }
            var index = findItem(target, method, this._throttlers);
            if (index > -1) {
                this._throttlers[index + 2] = args;
                return this._throttlers[index + 3];
            } // throttled
            wait = parseInt(wait, 10);
            var timer = this._platform.setTimeout(function () {
                var i = findTimer(timer, _this2._throttlers);

                var _throttlers$splice = _this2._throttlers.splice(i, 4),
                    context = _throttlers$splice[0],
                    func = _throttlers$splice[1],
                    params = _throttlers$splice[2];

                if (isImmediate === false) {
                    _this2._run(context, func, params);
                }
            }, wait);
            if (isImmediate) {
                this._join(target, method, args);
            }
            this._throttlers.push(target, method, args, timer);
            return timer;
        };

        Backburner.prototype.debounce = function debounce(targetOrThisArgOrMethod) {
            var _this3 = this;

            var target = void 0;
            var method = void 0;
            var immediate = void 0;
            var isImmediate = void 0;
            var wait = void 0;

            for (var _len5 = arguments.length, args = Array(_len5 > 1 ? _len5 - 1 : 0), _key5 = 1; _key5 < _len5; _key5++) {
                args[_key5 - 1] = arguments[_key5];
            }

            if (args.length === 1) {
                method = targetOrThisArgOrMethod;
                wait = args.pop();
                target = null;
                isImmediate = false;
            } else {
                target = targetOrThisArgOrMethod;
                method = args.shift();
                immediate = args.pop();
                if (isString(method)) {
                    method = target[method];
                } else if (!isFunction(method)) {
                    args.unshift(method);
                    method = target;
                    target = null;
                }
                if (isCoercableNumber(immediate)) {
                    wait = immediate;
                    isImmediate = false;
                } else {
                    wait = args.pop();
                    isImmediate = immediate === true;
                }
            }
            wait = parseInt(wait, 10);
            // Remove debouncee
            var index = findItem(target, method, this._debouncees);
            if (index > -1) {
                var timerId = this._debouncees[index + 3];
                this._platform.clearTimeout(timerId);
                this._debouncees.splice(index, 4);
            }
            var timer = this._platform.setTimeout(function () {
                var i = findTimer(timer, _this3._debouncees);

                var _debouncees$splice = _this3._debouncees.splice(i, 4),
                    context = _debouncees$splice[0],
                    func = _debouncees$splice[1],
                    params = _debouncees$splice[2];

                if (isImmediate === false) {
                    _this3._run(context, func, params);
                }
            }, wait);
            if (isImmediate && index === -1) {
                this._join(target, method, args);
            }
            this._debouncees.push(target, method, args, timer);
            return timer;
        };

        Backburner.prototype.cancelTimers = function cancelTimers() {
            for (var i = 3; i < this._throttlers.length; i += 4) {
                this._platform.clearTimeout(this._throttlers[i]);
            }
            this._throttlers = [];
            for (var t = 3; t < this._debouncees.length; t += 4) {
                this._platform.clearTimeout(this._debouncees[t]);
            }
            this._debouncees = [];
            this._clearTimerTimeout();
            this._timers = [];
            this._cancelAutorun();
        };

        Backburner.prototype.hasTimers = function hasTimers() {
            return this._timers.length > 0 || this._debouncees.length > 0 || this._throttlers.length > 0 || this._autorun !== null;
        };

        Backburner.prototype.cancel = function cancel(timer) {
            if (!timer) {
                return false;
            }
            var timerType = typeof timer;
            if (timerType === 'number' || timerType === 'string') {
                return this._cancelItem(timer, this._throttlers) || this._cancelItem(timer, this._debouncees);
            } else if (timerType === 'function') {
                return this._cancelLaterTimer(timer);
            } else if (timerType === 'object' && timer.queue && timer.method) {
                return timer.queue.cancel(timer);
            }
            return false;
        };

        Backburner.prototype.ensureInstance = function ensureInstance() {
            this._ensureInstance();
        };

        Backburner.prototype._join = function _join(target, method, args) {
            if (this.currentInstance === null) {
                return this._run(target, method, args);
            }
            if (target === undefined && args === undefined) {
                return method();
            } else {
                return method.apply(target, args);
            }
        };

        Backburner.prototype._run = function _run(target, method, args) {
            var onError = getOnError(this.options);
            this.begin();
            if (onError) {
                try {
                    return method.apply(target, args);
                } catch (error) {
                    onError(error);
                } finally {
                    this.end();
                }
            } else {
                try {
                    return method.apply(target, args);
                } finally {
                    this.end();
                }
            }
        };

        Backburner.prototype._cancelAutorun = function _cancelAutorun() {
            if (this._autorun !== null) {
                this._platform.clearNext(this._autorun);
                this._autorun = null;
            }
        };

        Backburner.prototype._setTimeout = function _setTimeout(fn, executeAt) {
            if (this._timers.length === 0) {
                this._timers.push(executeAt, fn);
                this._installTimerTimeout();
                return fn;
            }
            // find position to insert
            var i = binarySearch(executeAt, this._timers);
            this._timers.splice(i, 0, executeAt, fn);
            // we should be the new earliest timer if i == 0
            if (i === 0) {
                this._reinstallTimerTimeout();
            }
            return fn;
        };

        Backburner.prototype._cancelLaterTimer = function _cancelLaterTimer(timer) {
            for (var i = 1; i < this._timers.length; i += 2) {
                if (this._timers[i] === timer) {
                    i = i - 1;
                    this._timers.splice(i, 2); // remove the two elements
                    if (i === 0) {
                        this._reinstallTimerTimeout();
                    }
                    return true;
                }
            }
            return false;
        };

        Backburner.prototype._cancelItem = function _cancelItem(timer, array) {
            var index = findTimer(timer, array);
            if (index > -1) {
                this._platform.clearTimeout(timer);
                array.splice(index, 4);
                return true;
            }
            return false;
        };

        Backburner.prototype._trigger = function _trigger(eventName, arg1, arg2) {
            var callbacks = this._eventCallbacks[eventName];
            if (callbacks !== undefined) {
                for (var i = 0; i < callbacks.length; i++) {
                    callbacks[i](arg1, arg2);
                }
            }
        };

        Backburner.prototype._runExpiredTimers = function _runExpiredTimers() {
            this._timerTimeoutId = null;
            if (this._timers.length === 0) {
                return;
            }
            this.begin();
            this._scheduleExpiredTimers();
            this.end();
        };

        Backburner.prototype._scheduleExpiredTimers = function _scheduleExpiredTimers() {
            var timers = this._timers;
            var l = timers.length;
            var i = 0;
            var defaultQueue = this.options.defaultQueue;
            var n = this._platform.now();
            for (; i < l; i += 2) {
                var executeAt = timers[i];
                if (executeAt <= n) {
                    var fn = timers[i + 1];
                    this.schedule(defaultQueue, null, fn);
                } else {
                    break;
                }
            }
            timers.splice(0, i);
            this._installTimerTimeout();
        };

        Backburner.prototype._reinstallTimerTimeout = function _reinstallTimerTimeout() {
            this._clearTimerTimeout();
            this._installTimerTimeout();
        };

        Backburner.prototype._clearTimerTimeout = function _clearTimerTimeout() {
            if (this._timerTimeoutId === null) {
                return;
            }
            this._platform.clearTimeout(this._timerTimeoutId);
            this._timerTimeoutId = null;
        };

        Backburner.prototype._installTimerTimeout = function _installTimerTimeout() {
            if (this._timers.length === 0) {
                return;
            }
            var minExpiresAt = this._timers[0];
            var n = this._platform.now();
            var wait = Math.max(0, minExpiresAt - n);
            this._timerTimeoutId = this._platform.setTimeout(this._boundRunExpiredTimers, wait);
        };

        Backburner.prototype._ensureInstance = function _ensureInstance() {
            var currentInstance = this.currentInstance;
            if (currentInstance === null) {
                currentInstance = this.begin();
                var next = this._platform.next;
                this._autorun = next(this._boundAutorunEnd);
            }
            return currentInstance;
        };

        return Backburner;
    }();

    Backburner.Queue = Queue;

    exports.default = Backburner;
});