enifed('ember-glimmer/template', ['exports', 'ember-babel', '@glimmer/runtime', 'ember-utils'], function (exports, _emberBabel, _runtime, _emberUtils) {
    'use strict';

    exports.WrappedTemplateFactory = undefined;
    exports.default = template;

    var WrappedTemplateFactory = exports.WrappedTemplateFactory = function () {
        function WrappedTemplateFactory(factory) {
            (0, _emberBabel.classCallCheck)(this, WrappedTemplateFactory);

            this.factory = factory;
            this.id = factory.id;
            this.meta = factory.meta;
        }

        WrappedTemplateFactory.prototype.create = function create(props) {
            var owner = props[_emberUtils.OWNER];
            return this.factory.create(props.env, { owner: owner });
        };

        return WrappedTemplateFactory;
    }();

    function template(json) {
        var factory = (0, _runtime.templateFactory)(json);
        return new WrappedTemplateFactory(factory);
    }
});