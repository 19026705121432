define("ember-cli-materialize/components/md-textarea", ["exports", "ember-cli-materialize/components/md-input-field", "ember-cli-materialize/templates/components/md-textarea"], function (_exports, _mdInputField, _mdTextarea) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mdInputField.default.extend({
    layout: _mdTextarea.default,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments); // make sure the label moves when a value is bound.


      this._setupLabel();
    }
  });

  _exports.default = _default;
});