define("ember-cli-materialize/services/md-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // jscs:disable disallowDirectPropertyAccess
  var keys = Object.keys || Ember.keys; // jscs:enable disallowDirectPropertyAccess

  var _default = Ember.Service.extend({
    // Footer
    modalIsFooterFixed: Ember.computed.oneWay('defaultModalIsFooterFixed'),
    // Button
    buttonIconPosition: Ember.computed.oneWay('defaultButtonIconPosition'),
    // Loader
    loaderSize: Ember.computed.oneWay('defaultLoaderSize'),
    loaderMode: Ember.computed.oneWay('defaultLoaderMode'),
    // Modal
    modalContainerId: Ember.computed.oneWay('defaultModalContainerId'),
    // Animation (Dropdown Button)
    dropdownInDuration: Ember.computed.oneWay('defaultDropdownInDuration'),
    dropdownOutDuration: Ember.computed.oneWay('defaultDropdownOutDuration'),
    init: function init() {
      this._super.apply(this, arguments);

      this._setDefaults();
    },
    _setDefaults: function _setDefaults() {
      var _this = this;

      var defaults = Ember.getWithDefault(this, 'materializeDefaults', {});
      keys(defaults).map(function (key) {
        var classifiedKey = Ember.String.classify(key);
        var defaultKey = "default".concat(classifiedKey);
        return Ember.set(_this, defaultKey, defaults[key]);
      });
    }
  });

  _exports.default = _default;
});