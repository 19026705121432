define("ember-cli-materialize/components/md-copyright", ["exports", "ember-cli-materialize/templates/components/md-copyright"], function (_exports, _mdCopyright) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _mdCopyright.default,
    classNames: ['footer-copyright'],
    init: function init() {
      this._super.apply(this, arguments);

      (true && !(this.get('startYear') === null || this.get('startYear') <= new Date().getFullYear()) && Ember.assert('Property startYear must be less than or equal to the current year.', this.get('startYear') === null || this.get('startYear') <= new Date().getFullYear()));
    },
    startYear: null,
    text: null,
    date: Ember.computed(function () {
      var currentYear = new Date().getFullYear();
      var startYear = this.get('startYear');

      if (startYear === null || startYear === currentYear) {
        return "".concat(currentYear);
      } else {
        return "".concat(startYear, " - ").concat(currentYear);
      }
    })
  });

  _exports.default = _default;
});