enifed('ember-glimmer/utils/process-args', ['exports', 'ember-babel', 'ember-utils', 'ember-views', 'ember-glimmer/component', 'ember-glimmer/helpers/action', 'ember-glimmer/utils/references'], function (exports, _emberBabel, _emberUtils, _emberViews, _component, _action, _references) {
    'use strict';

    exports.processComponentArgs = processComponentArgs;

    // ComponentArgs takes EvaluatedNamedArgs and converts them into the
    // inputs needed by CurlyComponents (attrs and props, with mutable
    // cells, etc).
    function processComponentArgs(namedArgs) {
        var keys = namedArgs.names;
        var attrs = namedArgs.value();
        var props = Object.create(null);
        var args = Object.create(null);
        props[_component.ARGS] = args;
        for (var i = 0; i < keys.length; i++) {
            var name = keys[i];
            var ref = namedArgs.get(name);
            var value = attrs[name];
            if (typeof value === 'function' && value[_action.ACTION]) {
                attrs[name] = value;
            } else if (ref[_references.UPDATE]) {
                attrs[name] = new MutableCell(ref, value);
            }
            args[name] = ref;
            props[name] = value;
        }
        props.attrs = attrs;
        return props;
    }
    var REF = (0, _emberUtils.symbol)('REF');

    var MutableCell = function () {
        function MutableCell(ref, value) {
            (0, _emberBabel.classCallCheck)(this, MutableCell);

            this[_emberViews.MUTABLE_CELL] = true;
            this[REF] = ref;
            this.value = value;
        }

        MutableCell.prototype.update = function update(val) {
            this[REF][_references.UPDATE](val);
        };

        return MutableCell;
    }();
});