define("ember-cli-materialize/components/md-radio", ["exports", "ember-cli-materialize/components/selectable-item", "ember-cli-materialize/templates/components/md-radio"], function (_exports, _selectableItem, _mdRadio) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _selectableItem.default.extend({
    layout: _mdRadio.default,
    value: '',
    text: Ember.computed.alias('name'),
    groupValue: Ember.computed.alias('group.selection'),
    className: ['materialize-radio'],
    checked: Ember.computed('groupValue', 'value', function () {
      return this.get('groupValue') === this.get('value');
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      (true && !('materialize-radio is not supported outside the context of a materialize-radio-group') && Ember.assert(!Ember.isEmpty(this.get('group')), 'materialize-radio is not supported outside the context of a materialize-radio-group'));
    }
  });

  _exports.default = _default;
});