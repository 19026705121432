enifed("ember-glimmer/template_registry", ["exports"], function (exports) {
    "use strict";

    exports.setTemplates = setTemplates;
    exports.getTemplates = getTemplates;
    exports.getTemplate = getTemplate;
    exports.hasTemplate = hasTemplate;
    exports.setTemplate = setTemplate;
    var TEMPLATES = {};
    function setTemplates(templates) {
        TEMPLATES = templates;
    }
    function getTemplates() {
        return TEMPLATES;
    }
    function getTemplate(name) {
        if (TEMPLATES.hasOwnProperty(name)) {
            return TEMPLATES[name];
        }
    }
    function hasTemplate(name) {
        return TEMPLATES.hasOwnProperty(name);
    }
    function setTemplate(name, template) {
        return TEMPLATES[name] = template;
    }
});