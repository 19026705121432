define("ember-cli-materialize/components/md-btn", ["exports", "ember-cli-materialize/mixins/uses-settings", "ember-cli-materialize/templates/components/md-btn"], function (_exports, _usesSettings, _mdBtn) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_usesSettings.default, {
    layout: _mdBtn.default,
    tagName: 'a',
    classNameBindings: ['btn:waves-effect', 'wavesClass', 'isDisabled:disabled:waves-effect', 'buttonClass'],
    attributeBindings: ['isDisabled:disabled'],
    wavesClass: 'waves-light',
    text: null,
    icon: null,
    iconPosition: null,
    buttonType: null,
    actionArg: null,
    isFlat: Ember.computed.equal('buttonType', 'flat'),
    isDisabled: false,
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.get('iconPosition')) {
        this.set('iconPosition', this.get('_mdSettings.buttonIconPosition'));
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      Ember.run.scheduleOnce('afterRender', this, this._setupWaves);
    },
    buttonClass: Ember.computed('buttonType', function () {
      var buttonType = this.get('buttonType');
      return buttonType ? "btn-".concat(buttonType) : 'btn';
    }),
    _setupWaves: function _setupWaves() {
      var Waves = window.Waves || {};

      if (Ember.typeOf(Waves.displayEffect) === 'function') {
        Waves.displayEffect();
      }
    },
    click: function click() {
      if (!this.get('isDisabled')) {
        this.sendAction('action', this.get('actionArg'));
      }
    }
  });

  _exports.default = _default;
});