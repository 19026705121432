enifed('ember-glimmer/utils/to-bool', ['exports', 'ember-metal', 'ember-runtime'], function (exports, _emberMetal, _emberRuntime) {
    'use strict';

    exports.default = toBool;
    function toBool(predicate) {
        if (!predicate) {
            return false;
        }
        if (predicate === true) {
            return true;
        }
        if ((0, _emberRuntime.isArray)(predicate)) {
            return (0, _emberMetal.get)(predicate, 'length') !== 0;
        }
        return true;
    }
});