enifed('ember-glimmer/helpers/concat', ['exports', '@glimmer/runtime', 'ember-glimmer/utils/references'], function (exports, _runtime, _references) {
    'use strict';

    exports.default = function (_vm, args) {
        return new _references.InternalHelperReference(concat, args.capture());
    };

    /**
    @module ember
    */
    /**
      Concatenates the given arguments into a string.
    
      Example:
    
      ```handlebars
      {{some-component name=(concat firstName " " lastName)}}
    
      {{! would pass name="<first name value> <last name value>" to the component}}
      ```
    
      @public
      @method concat
      @for Ember.Templates.helpers
      @since 1.13.0
    */
    function concat(_ref) {
        var positional = _ref.positional;

        return positional.value().map(_runtime.normalizeTextValue).join('');
    }
});