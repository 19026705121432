define("ember-cli-materialize/components/md-radios", ["exports", "ember-cli-materialize/components/selectable-item-group"], function (_exports, _selectableItemGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _selectableItemGroup.default.extend({
    classNames: ['md-radios'],
    selectableItemView: 'md-radios-radio'
  });

  _exports.default = _default;
});