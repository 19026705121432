define("ember-cli-materialize/components/md-range", ["exports", "ember-cli-materialize/templates/components/md-range"], function (_exports, _mdRange) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _mdRange.default,
    classNames: ['md-range'],
    min: 0,
    max: 100,
    step: 1
  });

  _exports.default = _default;
});