define("ember-cli-materialize/components/md-switch", ["exports", "ember-cli-materialize/components/selectable-item", "ember-cli-materialize/templates/components/md-switch"], function (_exports, _selectableItem, _mdSwitch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _selectableItem.default.extend({
    layout: _mdSwitch.default,
    classNames: ['switch', 'materialize-switch'],
    offLabel: 'Off',
    onLabel: 'On',
    disabled: false,
    _labelClass: Ember.computed('name', function () {
      return this.get('name') ? 'right' : '';
    })
  });

  _exports.default = _default;
});