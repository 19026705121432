define("ember-cli-materialize/components/md-radios-radio", ["exports", "ember-cli-materialize/components/md-radio", "ember-cli-materialize/mixins/group-selectable-item"], function (_exports, _mdRadio, _groupSelectableItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mdRadio.default.extend(_groupSelectableItem.default, {});

  _exports.default = _default;
});