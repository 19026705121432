enifed('ember-glimmer/component-managers/mount', ['exports', 'ember-babel', '@glimmer/runtime', 'ember-routing', 'ember/features', 'ember-glimmer/utils/references', 'ember-glimmer/component-managers/abstract', 'ember-glimmer/component-managers/outlet'], function (exports, _emberBabel, _runtime, _emberRouting, _features, _references, _abstract, _outlet) {
    'use strict';

    exports.MountDefinition = undefined;

    var MountManager = function (_AbstractManager) {
        (0, _emberBabel.inherits)(MountManager, _AbstractManager);

        function MountManager() {
            (0, _emberBabel.classCallCheck)(this, MountManager);
            return (0, _emberBabel.possibleConstructorReturn)(this, _AbstractManager.apply(this, arguments));
        }

        MountManager.prototype.create = function create(environment, _ref, args) {
            var name = _ref.name;

            if (true) {
                this._pushEngineToDebugStack('engine:' + name, environment);
            }
            var engine = environment.owner.buildChildEngineInstance(name);
            engine.boot();
            var bucket = { engine: engine };
            if (_features.EMBER_ENGINES_MOUNT_PARAMS) {
                bucket.modelReference = args.named.get('model');
            }
            return bucket;
        };

        MountManager.prototype.layoutFor = function layoutFor(_definition, _ref2, env) {
            var engine = _ref2.engine;

            var template = engine.lookup('template:application');
            return env.getCompiledBlock(_outlet.OutletLayoutCompiler, template);
        };

        MountManager.prototype.getSelf = function getSelf(bucket) {
            var engine = bucket.engine,
                modelReference = bucket.modelReference;

            var applicationFactory = engine.factoryFor('controller:application');
            var controllerFactory = applicationFactory || (0, _emberRouting.generateControllerFactory)(engine, 'application');
            var controller = bucket.controller = controllerFactory.create();
            if (_features.EMBER_ENGINES_MOUNT_PARAMS) {
                var model = modelReference.value();
                bucket.modelRevision = modelReference.tag.value();
                controller.set('model', model);
            }
            return new _references.RootReference(controller);
        };

        MountManager.prototype.getDestructor = function getDestructor(_ref3) {
            var engine = _ref3.engine;

            return engine;
        };

        MountManager.prototype.didRenderLayout = function didRenderLayout() {
            if (true) {
                this.debugStack.pop();
            }
        };

        MountManager.prototype.update = function update(bucket) {
            if (_features.EMBER_ENGINES_MOUNT_PARAMS) {
                var controller = bucket.controller,
                    modelReference = bucket.modelReference,
                    modelRevision = bucket.modelRevision;

                if (!modelReference.tag.validate(modelRevision)) {
                    var model = modelReference.value();
                    bucket.modelRevision = modelReference.tag.value();
                    controller.set('model', model);
                }
            }
        };

        return MountManager;
    }(_abstract.default);

    var MOUNT_MANAGER = new MountManager();

    var MountDefinition = exports.MountDefinition = function (_ComponentDefinition) {
        (0, _emberBabel.inherits)(MountDefinition, _ComponentDefinition);

        function MountDefinition(name) {
            (0, _emberBabel.classCallCheck)(this, MountDefinition);
            return (0, _emberBabel.possibleConstructorReturn)(this, _ComponentDefinition.call(this, name, MOUNT_MANAGER, null));
        }

        return MountDefinition;
    }(_runtime.ComponentDefinition);
});