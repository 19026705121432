define('ember-keyboard/fixtures/alt-key-names', ['exports'], function (exports) {
  exports['default'] = {
    'Add': '+',
    'Decimal': '.',
    'Del': 'Delete',
    'Divide': '/',
    'Down': 'ArrowDown',
    'Esc': 'Escape',
    'Left': 'ArrowLeft',
    'Multiply': '*',
    'Right': 'ArrowRight',
    'Scroll': 'ScrollLock',
    'Spacebar': ' ',
    'Subtract': '-',
    'Up': 'ArrowUp'
  };
});