enifed('ember-glimmer/syntax/render', ['exports', '@glimmer/reference', 'ember-debug', 'ember-glimmer/component-managers/render', 'ember-glimmer/syntax/utils'], function (exports, _reference, _emberDebug, _render, _utils) {
    'use strict';

    exports.renderMacro = renderMacro;
    /**
    @module ember
    */
    function makeComponentDefinition(vm, args) {
        var env = vm.env;
        var nameRef = args.positional.at(0);
        (true && !((0, _reference.isConst)(nameRef)) && (0, _emberDebug.assert)('The first argument of {{render}} must be quoted, e.g. {{render "sidebar"}}.', (0, _reference.isConst)(nameRef)));
        (true && !(args.positional.length === 1 || !(0, _reference.isConst)(args.positional.at(1))) && (0, _emberDebug.assert)('The second argument of {{render}} must be a path, e.g. {{render "post" post}}.', args.positional.length === 1 || !(0, _reference.isConst)(args.positional.at(1))));

        var templateName = nameRef.value();
        // tslint:disable-next-line:max-line-length
        (true && !(env.owner.hasRegistration('template:' + templateName)) && (0, _emberDebug.assert)('You used `{{render \'' + templateName + '\'}}`, but \'' + templateName + '\' can not be found as a template.', env.owner.hasRegistration('template:' + templateName)));

        var template = env.owner.lookup('template:' + templateName);
        var controllerName = void 0;
        if (args.named.has('controller')) {
            var controllerNameRef = args.named.get('controller');
            // tslint:disable-next-line:max-line-length
            (true && !((0, _reference.isConst)(controllerNameRef)) && (0, _emberDebug.assert)('The controller argument for {{render}} must be quoted, e.g. {{render "sidebar" controller="foo"}}.', (0, _reference.isConst)(controllerNameRef)));

            // TODO should be ensuring this to string here
            controllerName = controllerNameRef.value();
            // tslint:disable-next-line:max-line-length
            (true && !(env.owner.hasRegistration('controller:' + controllerName)) && (0, _emberDebug.assert)('The controller name you supplied \'' + controllerName + '\' did not resolve to a controller.', env.owner.hasRegistration('controller:' + controllerName)));
        } else {
            controllerName = templateName;
        }
        if (args.positional.length === 1) {
            return new _reference.ConstReference(new _render.RenderDefinition(controllerName, template, env, _render.SINGLETON_RENDER_MANAGER));
        } else {
            return new _reference.ConstReference(new _render.RenderDefinition(controllerName, template, env, _render.NON_SINGLETON_RENDER_MANAGER));
        }
    }
    /**
      Calling ``{{render}}`` from within a template will insert another
      template that matches the provided name. The inserted template will
      access its properties on its own controller (rather than the controller
      of the parent template).
    
      If a view class with the same name exists, the view class also will be used.
      Note: A given controller may only be used *once* in your app in this manner.
      A singleton instance of the controller will be created for you.
    
      Example:
    
      ```app/controllers/navigation.js
      import Controller from '@ember/controller';
    
      export default Controller.extend({
        who: "world"
      });
      ```
    
      ```handlebars
      <!-- navigation.hbs -->
      Hello, {{who}}.
      ```
    
      ```handlebars
      <!-- application.hbs -->
      <h1>My great app</h1>
      {{render "navigation"}}
      ```
    
      ```html
      <h1>My great app</h1>
      <div class='ember-view'>
        Hello, world.
      </div>
      ```
    
      Optionally you may provide a second argument: a property path
      that will be bound to the `model` property of the controller.
      If a `model` property path is specified, then a new instance of the
      controller will be created and `{{render}}` can be used multiple times
      with the same name.
    
      For example if you had this `author` template.
    
      ```handlebars
      <div class="author">
        Written by {{firstName}} {{lastName}}.
        Total Posts: {{postCount}}
      </div>
      ```
    
      You could render it inside the `post` template using the `render` helper.
    
      ```handlebars
      <div class="post">
        <h1>{{title}}</h1>
        <div>{{body}}</div>
        {{render "author" author}}
      </div>
      ```
    
      @method render
      @for Ember.Templates.helpers
      @param {String} name
      @param {Object?} context
      @param {Hash} options
      @return {String} HTML string
      @public
      @deprecated Use a component instead
    */
    function renderMacro(_name, params, hash, builder) {
        if (!params) {
            params = [];
        }
        var definitionArgs = [params.slice(0), hash, null, null];
        var args = [params.slice(1), (0, _utils.hashToArgs)(hash), null, null];
        builder.component.dynamic(definitionArgs, makeComponentDefinition, args);
        return true;
    }
});