enifed('ember-glimmer/components/text_field', ['exports', 'ember-environment', 'ember-metal', 'ember-views', 'ember-glimmer/component', 'ember-glimmer/templates/empty'], function (exports, _emberEnvironment, _emberMetal, _emberViews, _component, _empty) {
    'use strict';

    var inputTypes = Object.create(null); /**
                                          @module @ember/component
                                          */

    function canSetTypeOfInput(type) {
        if (type in inputTypes) {
            return inputTypes[type];
        }
        // if running in outside of a browser always return the
        // original type
        if (!_emberEnvironment.environment.hasDOM) {
            inputTypes[type] = type;
            return type;
        }
        var inputTypeTestElement = document.createElement('input');
        try {
            inputTypeTestElement.type = type;
        } catch (e) {
            // ignored
        }
        return inputTypes[type] = inputTypeTestElement.type === type;
    }
    /**
    
      The internal class used to create text inputs when the `{{input}}`
      helper is used with `type` of `text`.
    
      See [Ember.Templates.helpers.input](/api/classes/Ember.Templates.helpers.html#method_input)  for usage details.
    
      ## Layout and LayoutName properties
    
      Because HTML `input` elements are self closing `layout` and `layoutName`
      properties will not be applied.
    
      @class TextField
      @extends Component
      @uses Ember.TextSupport
      @public
    */
    exports.default = _component.default.extend(_emberViews.TextSupport, {
        layout: _empty.default,
        classNames: ['ember-text-field'],
        tagName: 'input',
        attributeBindings: ['accept', 'autocomplete', 'autosave', 'dir', 'formaction', 'formenctype', 'formmethod', 'formnovalidate', 'formtarget', 'height', 'inputmode', 'lang', 'list', 'type', 'max', 'min', 'multiple', 'name', 'pattern', 'size', 'step', 'value', 'width'],
        /**
          The `value` attribute of the input element. As the user inputs text, this
          property is updated live.
             @property value
          @type String
          @default ""
          @public
        */
        value: '',
        /**
          The `type` attribute of the input element.
             @property type
          @type String
          @default "text"
          @public
        */
        type: (0, _emberMetal.computed)({
            get: function () {
                return 'text';
            },
            set: function (_key, value) {
                var type = 'text';
                if (canSetTypeOfInput(value)) {
                    type = value;
                }
                return type;
            }
        }),
        /**
          The `size` of the text field in characters.
             @property size
          @type String
          @default null
          @public
        */
        size: null,
        /**
          The `pattern` attribute of input element.
             @property pattern
          @type String
          @default null
          @public
        */
        pattern: null,
        /**
          The `min` attribute of input element used with `type="number"` or `type="range"`.
             @property min
          @type String
          @default null
          @since 1.4.0
          @public
        */
        min: null,
        /**
          The `max` attribute of input element used with `type="number"` or `type="range"`.
             @property max
          @type String
          @default null
          @since 1.4.0
          @public
        */
        max: null
    });
});