define("ember-cli-materialize/components/md-checks", ["exports", "ember-cli-materialize/components/selectable-item-group"], function (_exports, _selectableItemGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _selectableItemGroup.default.extend({
    selectableItemView: 'md-checks-check',
    multiple: true
  });

  _exports.default = _default;
});