define("ember-cli-materialize/components/md-switches-switch", ["exports", "ember-cli-materialize/mixins/group-selectable-item", "ember-cli-materialize/components/md-switch"], function (_exports, _groupSelectableItem, _mdSwitch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mdSwitch.default.extend(_groupSelectableItem.default, {});

  _exports.default = _default;
});